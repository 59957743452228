/* eslint-disable camelcase */
import React, { useState, useContext } from 'react';
import {
  Row, Col, Card, Form, Button, Input, InputNumber, Select,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import collections from '../../../utils/collections';
import routes from '../../../utils/routes';
import { addDoc } from '../../../utils/firebase';
import { GlobalContext } from '../../../contexts/global';
import { SUPPLIERS } from '../../../utils/options';
import { filterOption } from '../../../utils/common';
import Page404 from '../../Page404';

const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const required = [{
  required: true,
  message: REQUIRED_MESSAGE,
}];
const numberConf = {
  controls: false,
  min: 0,
  decimalSeparator: ',',
  precision: 0,
  style: {
    width: '100%',
  },
};

export default function StockPaperIn() {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    PAPERS, syncStockPaper, isAdmin, isUretim, isGrafik,
  } = useContext(GlobalContext);

  async function onSubmit(values) {
    setLoading(true);

    await addDoc(collections.STOCK_PAPER, {
      ...values,
      consumed_boy: 0,
    });

    syncStockPaper();
    setLoading(false);
    navigate(routes.STOCK_PAPER);
  }

  if (!(isAdmin || isUretim || isGrafik)) {
    return <Page404 />;
  }

  return (
    <Row>
      <Col lg={24} xl={12} xxl={10}>
        <Card title="Kağıt Stok Giriş" bordered={false}>
          <Form
            form={form}
            onFinish={onSubmit}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              name="supplier"
              label="Tedarikçi Adı"
              rules={required}
            >
              <Select placeholder="Seçiniz" options={SUPPLIERS} />
            </Form.Item>
            <Form.Item
              name="material_type"
              label="Malzeme Türü"
              rules={required}
            >
              <Select
                placeholder="Seçiniz"
                options={PAPERS}
                showSearch
                filterOption={filterOption}
              />
            </Form.Item>
            <Form.Item
              name="material_code"
              label="Malzeme Kodu"
              rules={required}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="en"
              label="En"
              rules={required}
            >
              <InputNumber {...numberConf} precision="2" />
            </Form.Item>
            <Form.Item
              name="boy"
              label="Boy"
              rules={required}
            >
              <InputNumber {...numberConf} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                icon={React.createElement(SaveOutlined)}
                loading={isLoading}
              >
                Kaydet
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
