import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  addDoc as _addDoc,
  deleteDoc as _deleteDoc,
  getDoc as _getDoc,
  getDocs as _getDocs,
  updateDoc as _updateDoc,
  and,
  collection,
  doc,
  getFirestore,
  initializeFirestore,
  or,
  orderBy,
  query,
  serverTimestamp,
  where,
} from '@firebase/firestore';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});

export const auth = getAuth(app);
export const db = getFirestore(app);

export const addDoc = (collectionName, values) => _addDoc(collection(db, collectionName), {
  ...values,
  created_by: auth.currentUser.uid,
  created_at: serverTimestamp(),
});

export const queryAll = (collectionName, sort = 'desc', sortBy = 'created_at', ...rest) => query(collection(db, collectionName), orderBy(sortBy, sort), ...rest);

export const queryWhere = (collectionName, q, sort = 'desc', sortBy = 'created_at', ...rest) => query(collection(db, collectionName), q, orderBy(sortBy, sort), ...rest);

export const getDoc = (collectionName, id) => _getDoc(doc(db, collectionName, id))
  .then((row) => ({ ...row.data(), id: row.id }));

export const getDocs = (q) => _getDocs(q)
  .then((snapshot) => (snapshot.docs.map((row) => ({ ...row.data(), id: row.id }))));

export const deleteDoc = (collectionName, id) => _deleteDoc(doc(db, collectionName, id));

export const updateDoc = (collectionName, id, values) => _updateDoc(
  doc(db, collectionName, id),
  {
    ...values,
    updated_by: auth.currentUser.uid,
    updated_at: serverTimestamp(),
  },
);

export { and, or, where };

export const getJWT = () => auth?.currentUser?.getIdToken();
