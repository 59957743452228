import React, { useState, useContext } from 'react';
import {
  Row, Col, Card, Form, Button, Input,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import routes from '../../utils/routes';
import collections from '../../utils/collections';
import { generateSearchTerms } from '../../utils/common';
import { auth, addDoc } from '../../utils/firebase';
import { GlobalContext } from '../../contexts/global';
import Page404 from '../Page404';

const { TextArea } = Input;
const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const required = [{
  required: true,
  message: REQUIRED_MESSAGE,
}];

export default function ClientCreate() {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { syncClients, isAdmin, isTemsilci } = useContext(GlobalContext);

  async function onSubmit(values) {
    setLoading(true);
    await addDoc(collections.CLIENTS, {
      ...values,
      representative_id: auth.currentUser.uid,
      name_search_terms: generateSearchTerms(values.name),
    });
    setLoading(false);
    syncClients();
    navigate(routes.CLIENTS);
  }

  if (!(isAdmin || isTemsilci)) {
    return <Page404 />;
  }

  return (
    <Row>
      <Col lg={24} xl={12} xxl={10}>
        <Card title="Yeni Müşteri Oluştur" bordered={false}>
          <Form
            form={form}
            onFinish={onSubmit}
            autoComplete="off"
            layout="vertical"
          >
            <p>Müşteri iletişim, adres ve ödeme anlaşılan ödeme koşullarını belirtiniz.</p>
            <Form.Item
              name="name"
              label="Firma Ticaret Ünvanı"
              rules={required}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name_surname"
              label="Yetkili - İsim Soyisim"
              rules={required}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone_number"
              label="Telefon Numarası"
              rules={[...required, {
                pattern: /^[0-9]*$/,
                message: 'Sadece rakam giriniz ve boşluk bırakmayınız.',
              }]}
            >
              <Input
                count={{
                  max: 15,
                }}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{
                type: 'email',
                message: 'Geçerli bir email adresi giriniz.',
              }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="address" label="Adres">
              <TextArea
                rows={2}
                style={{
                  resize: 'none',
                }}
              />
            </Form.Item>
            <Form.Item
              name="tax_office"
              label="Vergi Dairesi Bilgi"
            >
              <Input />
            </Form.Item>
            <Form.Item name="notes" label="Anlaşılan Çalışma Koşulları ve Diğer Notlar">
              <TextArea
                rows={3}
                style={{
                  resize: 'none',
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                icon={React.createElement(SaveOutlined)}
                loading={isLoading}
              >
                Kaydet
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
